<template>
    <div class="col-12 col-lg-12 sss-area-left animate__animated">
        <h4 class="mt-5">
            More About EduVal
        </h4>

        <!-- <div class="d-flex mb-5 justify-content-center faq-buttons">
            <a href="/pdf/EduvalFAQ.pdf" target="_blank">Download & Read Later <i class="ri-file-ppt-2-fill"></i></a>
        </div> -->
        <div class="accordion container" id="accordionhowItWorks">

            <div class="how-it-works-card">
                <div class="" id="heading1">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left" type="button" data-bs-toggle="collapse"
                            href="#collapse1" aria-expanded="false" aria-controls="collapse1">
                            1. EduVal
                            <i class="ri-add-circle-line"></i>
                        </button>
                    </h2>
                </div>

                <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#accordionhowItWorks"
                    style="">
                    <div class="accordionhow-it-works-desc scroller">
                        <p class="mt-2">
                            EduVal provides complete solutions for educational administration, achievement, and
                            productivity. One
                            could rightly characterize EduVal’s product suite as an academic administration “ERP”. It
                            represents a
                            complete comprehensive academic management software solution that literally does not exist
                            in the
                            educational sector today.
                        </p>

                    </div>
                </div>
            </div>
            <div class="how-it-works-card">
                <div class="" id="heading2">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button"
                            data-bs-toggle="collapse" href="#collapse2" aria-expanded="false" aria-controls="collapse2">
                            2. The Legacy Cost
                            <i class="ri-add-circle-line"></i>
                        </button>
                    </h2>
                </div>
                <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordionhowItWorks">
                    <div class="accordionhow-it-works-desc mt-3 scroller">
                        <p>
                            Historically, education administrators have relied heavily on manual (pencil and paper
                            systems) and
                            standalone siloed software tools to manage educational resources, processes, and
                            accreditation. The
                            weight of legacy traditions and bureaucracy is heavy.
                        </p>

                        <p>
                            There is a distinct lack of technology integration or the leveraging of technology deeply
                            into university
                            processes…, particularly on the academic management side.
                        </p>
                        <p>Bureaucratic and time-intensive processes absorb resources and in the end add to overhead
                            cost</p>

                        <p>
                            The inefficiencies in administration and management then necessarily become apparent and
                            tend to
                            feed on themselves, ultimately degrading the academic mission and negatively affecting
                            student
                            learning.
                        </p>

                        <p>
                            Much of the information schools and universities provide overseeing entities today is
                            literally the result
                            of manually entered and reported data
                        </p>

                        <p>
                            The cumulative and end result of legacy practices and processes is less effective for
                            student learning
                        </p>
                    </div>
                </div>
            </div>

            <div class="how-it-works-card">
                <div class="" id="heading3">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button"
                            data-bs-toggle="collapse" href="#collapse3" aria-expanded="false" aria-controls="collapse3">
                            3. The New Millennium Means Change
                            <i class="ri-add-circle-line"></i>
                        </button>
                    </h2>
                </div>
                <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordionhowItWorks">
                    <div class="accordionhow-it-works-desc mt-3 scroller">
                        <p>
                            Global competition, increased regulation, compliance initiatives, the influence of
                            technology, and rising
                            expectations of students, faculty and even parents of students are driving the need for
                            greater
                            accountability, responsiveness, and improved results for educational institutions.

                        </p>

                        <p>
                            Changes afoot in the education sector mirror those that are occurring globally in health
                            care and finance
                            sectors where the need for greater transparency, the effects of increased competition, and
                            the influence
                            of technology are creating new ways of doing things and also new opportunities for companies
                            like
                            EduVal.

                        </p>
                    </div>
                </div>
            </div>

            <div class="how-it-works-card">
                <div class="" id="heading4">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button"
                            data-bs-toggle="collapse" href="#collapse4" aria-expanded="false" aria-controls="collapse4">
                            4. Curriculum is a Starting Point of Change
                            <i class="ri-add-circle-line"></i>
                        </button>
                    </h2>
                </div>
                <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionhowItWorks">
                    <div class="accordionhow-it-works-desc mt-3 scroller">
                        <p>
                            For EduVal, the starting point of change is the EduVal Curriculum Management System. New
                            technology
                            over the past decade has allowed educational institutions to plan their curriculum in a more
                            effective
                            way, leading to key student/faculty/school interfaces for learning.
                        </p>

                        <p>
                            At the same token education institutions need to be able to properly review, and assess the
                            effectiveness of their curriculum, and the attainment of students’ of learning outcomes,
                            hence EduVal
                            Accreditation and Assessment Management System.
                        </p>
                    </div>
                </div>
            </div>

            <div class="how-it-works-card">
                <div class="" id="heading5">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button"
                            data-bs-toggle="collapse" href="#collapse5" aria-expanded="false" aria-controls="collapse5">
                            5. Compliance Represents another Unique Challenge
                            <i class="ri-add-circle-line"></i>
                        </button>
                    </h2>
                </div>
                <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionhowItWorks">
                    <div class="accordionhow-it-works-desc mt-3 scroller">
                        <p>
                            Another key area is where educational institutions are faced with ever-increasing
                            requirements and
                            challenges in compliance.
                        </p>

                        <p>
                            In short, educational institutions must report to multiple entities including governmental,
                            educational,
                            and professional entities. These entities all have an interest in accountability and
                            performance and
                            typically require periodic reporting on standards established by external organizations.
                        </p>

                        <p>
                            Today, the reporting processes for these requirements are often archaic in their structure
                            and require
                            resources of people and time that could alternatively be used within the institution in more
                            productive
                            ways. An accreditation process such as for the Middle States Association often takes up to
                            two years to
                            complete. Most of that time is spent in collecting, aggregating, and reporting learner
                            progress and
                            achievements.
                        </p>

                        <p>
                            EduVal systems leverage technology to do these compliance-related tasks…for example,
                            artifacts and
                            grades collected in the LMS can be aggregated and used in creating reports behind the scenes
                            and in
                            real-time…thus accomplishing tasks that even today are done manually inside our educational
                            institutions.
                        </p>
                    </div>
                </div>
            </div>

            <div class="how-it-works-card">
                <div class="" id="heading6">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button"
                            data-bs-toggle="collapse" href="#collapse6" aria-expanded="false" aria-controls="collapse6">
                            6. Other Change Opportunities Include
                            <i class="ri-add-circle-line"></i>
                        </button>
                    </h2>
                </div>
                <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionhowItWorks">
                    <div class="accordionhow-it-works-desc mt-3 scroller">
                        <p>
                            The possibilities are many and EduVal software is easily customized to accommodate
                            individual
                            educational institution priorities.
                        </p>

                        <p>
                            Using EduVal academic administrative tools, the functions become integrated, the data is
                            shared,
                            resources are saved, and the system’s output is of higher quality.
                        </p>

                        <p>
                            In sum, using legacy systems and processes, today’s educational institutions accomplish most
                            of these
                            functions in siloed and separate functional departments, often using tools that are not
                            connected and
                            data sets that are not integrated.
                        </p>

                        <p>
                            Now, evidence-based decisions can be made!
                        </p>
                    </div>
                </div>
            </div>

            <div class="how-it-works-card">
                <div class="" id="heading7">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button"
                            data-bs-toggle="collapse" href="#collapse7" aria-expanded="false" aria-controls="collapse7">
                            7. EduVal is More than a Software Solution
                            <i class="ri-add-circle-line"></i>
                        </button>
                    </h2>
                </div>
                <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordionhowItWorks">
                    <div class="accordionhow-it-works-desc mt-3 scroller">
                        <p>
                            To accomplish the foregoing tasks and opportunities, EduVal does not only present an
                            integrated suite
                            of software tools and functions, but also universally accepted content, criteria, and
                            business rules, with
                            intuitive navigation, and a familiar look and feel. Our solutions are interactive in order
                            to guide the user
                            to select the right option and make the right decision.
                        </p>

                        <p>
                            Our solutions are easily integrated with SIS, LMS, or any other system, which creates and
                            facilitates
                            many important functions including, course schedules, active programs, active courses,
                            faculty data,
                            learning outcomes, assignment grading, and activity/outcome/rubric mapping among many
                            others.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="mt-5">
            Frequently Asked Questions
        </h4>
        <div class="container">
            <div class="how-it-works-card">
            <div class="" id="headingOne">
                <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left" type="button" data-bs-toggle="collapse"
                        href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Why EduVal?
                        <i class="ri-add-circle-line"></i>
                    </button>
                </h2>
            </div>

            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionhowItWorks"
                style="">
                <div class="accordionhow-it-works-desc scroller">
                    <p>
                        The case for EduVal should be clear :
                    </p>
                    <p>The value of EduVal is that they represent an integrated system of tools
                        designed to facilitate the academic management process, with built in
                        content and criteria by academic administrators for academic
                        administrators. Our systems have been designed by academic reviewers,
                        college Deans, Provost, Chief Academic Officers, and Chief Information
                        Officers. </p>
                    <p>Not only does EduVal generate its own data using its suite of systems,
                        but is also designed to search existing data sources to provide input
                        for its management and analysis functions. Although all of its systems
                        are designed to work in tandem, anyone of them may be used by itself and
                        operate as a standalone system in support of academic administrative
                        tasks.</p>
                    <p>In addition to the foregoing, EduVal also offers learning content and
                        content design services. Not all institutions possess the organic
                        resources to create courses and effectively construct courseware for
                        specific content areas. Therefore, EduVal has the resources to provide
                        assistance in the areas of:</p>

                    <span><i class="ri-arrow-right-s-fill"></i> Strategic Planning and Business
                        Services</span>
                    <span><i class="ri-arrow-right-s-fill"></i> Program Review and Accreditation
                        Preparation</span>
                    <span><i class="ri-arrow-right-s-fill"></i> Course and Program
                        Development</span>
                    <span><i class="ri-arrow-right-s-fill"></i> Instructional Design</span>
                    <span><i class="ri-arrow-right-s-fill"></i> Training and Workshops</span>

                    <p>In Sum, EduVal offerings represent something that has been both necessary
                        and missing in today’s academic leadership and administration. EduVal
                        products leverage technology and represent oversight and decision making
                        tools that will change the way educational institutions do business.</p>

                </div>
            </div>
        </div>
        <div class="how-it-works-card">
            <div class="" id="headingTwo">
                <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-bs-toggle="collapse"
                        href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How can we help you?
                        <i class="ri-add-circle-line"></i>
                    </button>
                </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionhowItWorks">
                <div class="accordionhow-it-works-desc scroller">
                    <p>1 - We will help reduce your cost</p>
                    <p>2 - We will help you optimize your resources</p>
                    <p>3 - We will help you streamline your processes</p>
                    <p>4 - We will help you with your performance management</p>
                    <p>5 - We will partner with you for the success of your students and
                        institution.</p>
                    <p>Historically, education administrators have relied heavily on manual
                        (pencil and paper systems) and standalone siloed software tools to
                        manage educational resources, processes and accreditation. The weight of
                        legacy traditions and bureaucracy is heavy.</p>
                    <p>There is a distinct lack of technology integration or the leveraging of
                        technology deeply into university processes…particularly on the academic
                        management side. Bureaucratic and time intensive processes absorb
                        resources and in the end add to overhead cost. The inefficiencies in
                        administration and management then necessarily become apparent and tend
                        to feed on themselves, ultimately degrading the academic mission and
                        negatively affecting student learning. </p>
                    <p>Much of the information schools and universities provide overseeing
                        entities today is the literally the result of manually entered and
                        reported data. The cumulative and end result of legacy practices and
                        processes is less effectiveness for student learning</p>

                    <strong>The New Millennium Means Change</strong>
                    <p>Global competition, increased regulation, compliance initiatives,
                        influence of technology and rising expectations of students, faculty and
                        even parents of students are driving the need for greater
                        accountability, responsiveness, and improved results for educational
                        institutions. </p>
                    <p>Changes afoot in the education sector mirror those that are occurring
                        globally in health care and finance sectors where the need for greater
                        transparency, the effects of increased competition, and the influence of
                        technology are creating new ways of doing things and also new
                        opportunities for companies like EduVal.</p>

                    <strong>Curriculum is a Starting Point of Change </strong>
                    <p>For EduVal, the starting point of change is the EduVal Curriculum
                        Management System. New technology over the past decade has allowed
                        education institutions to plan their curriculum in a more effective way,
                        leading to key student/faculty/school interface for learning. </p>
                    <p>At the same token education institutions need to be able to properly
                        review, and assess the effectiveness of their curriculum, and the
                        attainment of students’ of learning outcomes, hence EduVal Accreditation
                        and Assessment Management System. </p>

                    <strong>Compliance Represents another Unique Challenge</strong>
                    <p>Another key area where educational institutions are faced with ever
                        increasing requirements and challenges is in compliance.</p>
                    <p>In short, educational institutions must report to multiple entities to
                        inlcude governmental, educational and professional entities. These
                        entities all have an interest in accountability and performance and
                        typically require periodic reporting on standards established by
                        external organizations.</p>
                    <p>Today, the reporting processes for these requirements are often archaic
                        in their structure and require resources of people and time that could
                        alternatively be used within the institution in more productive ways. An
                        accreditation process such as for the Middle States Association often
                        takes up to two years to complete. Most of that time is spent in
                        collecting, aggregating and reporting learner progress and achievements
                    </p>
                    <p>
                        EduVal systems leverage technology to do these compliance related
                        tasks…for example,
                        artifacts and grades collected in the LMS can be aggregated and used in
                        creating
                        reports behind the scenes and in real time…thus accomplishing tasks
                        which even today are
                        done manually inside our educational institutions.
                    </p>

                    <strong>Other Change Opportunities Include</strong>
                    <p>The possibilities are many and EduVal software is easily customized to
                        accommodate individual educational institution priorities. </p>
                    <p>Using EduVal academic administrative tools, the functions become
                        integrated, the data is shared, resources are saved, and the systems
                        output are of higher quality.</p>
                    <p>In sum, using legacy systems and process, today’s educational
                        institutions accomplish most of these functions in siloed and separate
                        functional departments, often using tools that are not connected and
                        data sets that are not integrated. </p>
                    <p>Now, evidence based decisions can be made!</p>




                </div>
            </div>
        </div>
        <div class="how-it-works-card">
            <div class="" id="headingThree">
                <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-bs-toggle="collapse"
                        href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        With you at every step
                        <i class="ri-add-circle-line"></i>
                    </button>
                </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionhowItWorks"
                style="">
                <div class="accordionhow-it-works-desc scroller">
                    <p>
                        EduVal does not only present an integrated suite of software tools and
                        functions, but also universally
                        accepted content, criteria, and business rules, with intuitive
                        navigation, and familiar look and feel.
                        Our solutions are interactive in order to guide the user to select the
                        right option, and make the right decision.
                        Our solutions are easily integrated with SIS, LMS, or any other system,
                        which creates and facilitates many important
                        functions including, course schedule, active programs, active courses,
                        faculty data, learning outcomes, assignment grading,
                        and activity/outcome/rubric mapping among many others. They will save
                        money and increase productivity. They are scalable and
                        customizable, built on the most current and up to date software industry
                        frameworks.
                    </p>
                    <p>
                        Conceived by University leaders, administrators and professors, they
                        represent a set of solutions that fills a gap existing today in
                        institutional leadership and will enhance effectiveness and
                        responsiveness to this sector’s needs.

                    </p>
                    <p>
                        We will be with you starting from your current situation, your pain
                        areas, your ambition, and going through brain storming, discussions,
                        best practices, and benchmarking, until we have a solution that meets
                        your unique requirements.
                    </p>
                </div>
            </div>
        </div>
        </div>     
    </div>

</template>

<script>
import $ from 'jquery'

export default {
    computed: {
    },
    components: {
    },
    data() {
        return {
        };
    },
    mounted() {
        $(`.nav-item`).removeClass(`active`);
        $(`.navbar-nav li`).eq(1).addClass(`active`);
    }
};
</script>