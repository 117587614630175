import $ from 'jquery'

var setOp = {
    getLangCode() {
        var langCode = window.location.pathname.split("/")[1];
        if (langCode == "" || langCode == undefined || langCode == null) {
            return "en";
        }

        return langCode;
    },
    rightToLeft() {
        document.body.style.direction = 'rtl';
    },
    textAnimation() {
        var fadetime = 1000;
        var delaytime = 1000;

        function carousel() {
            $('.entry:first-child').fadeIn(fadetime).delay(delaytime).queue(function () {
                $(this).fadeOut(fadetime).appendTo('.entries').dequeue();
            });
        }
        carousel();
        setInterval(function () {
            carousel()
        }, fadetime + delaytime);
    }
}

export default setOp