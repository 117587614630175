<template>
  <component :placeHolder="placeHolder" :is="optionComponent" />
</template> 
  
<script>
import $ from 'jquery'
import { defineAsyncComponent } from "vue";
const InstructionalDesign = defineAsyncComponent(() =>
  import("../../components/SubPages/InstructionalDesign.vue")
);

export default {
  data() {
    return {
      placeHolder: "",
    };
  },
  components: {},
  computed: {
    optionComponent() {
      return InstructionalDesign;
    },
  },
  mounted() {
    $(`#header-logo`).removeClass(`hidden`);
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
      $(`#header-logo`).removeClass(`hidden`);
    }
  },
};
</script>
  
<style>

</style>