<template>
    <div class="home">
        <FaqPage />
    </div>
</template>
  
<script>
// @ is an alias to /src
import $ from 'jquery'
import FaqPage from '@/components/FaqPage.vue'
export default {
    name: 'HomeView',
    components: {
        FaqPage
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
            $(`#header-logo`).removeClass(`hidden`);
        }
    },
    mounted() {
        $(`.nav-item`).removeClass(`active`);
        $(`#header-logo`).removeClass(`hidden`);
        this.scrollToTop();
    }
}
</script>
  