<template>
 <div class="wrapper">
 <HeaderView />
  <router-view/>
  <FooterView />
 </div>  
</template>

<script>
import HeaderView from "./views/HeaderView.vue";
import FooterView from "./views/FooterView.vue";

import LocalizationsEn from "./assets/Localizations/en.json";
import LocalizationsTr from "./assets/Localizations/tr.json";
import LocalizationsFr from "./assets/Localizations/fr.json";
import LocalizationsAr from "./assets/Localizations/ar.json";
import LocalizationsRu from "./assets/Localizations/ru.json";
import LocalizationsPt from "./assets/Localizations/pt.json";
import LocalizationsEs from "./assets/Localizations/es.json";

import helper from "./views/helper";

export default {
  components: {
    HeaderView,
    FooterView
  },
  mounted(){    
    this.$store.state.langCodeX = helper.getLangCode();

    if (this.$store.state.langCodeX == 'ar'){
      helper.rightToLeft();
    }

    this.$store.state.LocalizationsEn = LocalizationsEn;
    this.$store.state.LocalizationsTr = LocalizationsTr;
    this.$store.state.LocalizationsFr = LocalizationsFr;
    this.$store.state.LocalizationsAr = LocalizationsAr;
    this.$store.state.LocalizationsRu = LocalizationsRu;
    this.$store.state.LocalizationsPt = LocalizationsPt;
    this.$store.state.LocalizationsEs = LocalizationsEs;
  }
}
</script>


<style>
#app {  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
html * {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none !important;
}

.breadcrumb {
  font-size: 13px;
}

.mark,
mark {
  color: #ed1c24 !important;
  font-weight: 700;
  background-color: transparent !important;
  padding: 1px !important;
}

.header-language {
  cursor: pointer;
}
</style>
