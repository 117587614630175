<template>
  <component :placeHolder="placeHolder" :is="optionComponent" />
</template> 

<script>
import { defineAsyncComponent } from "vue";
const SiteFooter = defineAsyncComponent(() =>
  import("../components/Shared/FooterSection.vue")
);

export default {
  data() {
    return {
      placeHolder: "",
    };
  },
  components: {},
  computed: {
    optionComponent() {      
        return SiteFooter;      
    },
  },
  methods: {},
};
</script>

<style>
</style>