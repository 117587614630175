import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    langCodeX: "en",
    LocalizationsEn: [],
    LocalizationsTr: [],
    LocalizationsFr: [],
    LocalizationsAr: [],
    LocalizationsRu: [],
    LocalizationsEs: [],
    LocalizationsPt: [],
    domainLanguages: [],

    languagesNamesX: [{
            code: "en",
            name: "English"
        },
        {
            code: "tr",
            name: "Türkçe"
        },
        {
            code: "fr",
            name: "Français"
        },
        {
            code: "pt",
            name: "Português"
        },
        {
            code: "es",
            name: "Español"
        },
        {
            code: "ru",
            name: "Pусский"
        },
        {
            code: "ar",
            name: "العربية"
        }
    ],
  },
  getters: {
    getWordLocale: (state) => (word) => {
      if (state.langCodeX == "en") {
        if (state.LocalizationsEn[word] == undefined) {
          return word;
        }

        return state.LocalizationsEn[word];
      } else if (state.langCodeX == "tr") {
        if (state.LocalizationsTr[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word];
          }
          return word;
        }

        return state.LocalizationsTr[word];
      } else if (state.langCodeX == "fr") {
        if (state.LocalizationsFr[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word];
          }
          return word;
        }

        return state.LocalizationsFr[word];
      } else if (state.langCodeX == "ar") {
        if (state.LocalizationsAr[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word];
          }
          return word;
        }

        return state.LocalizationsAr[word];
      } else if (state.langCodeX == "ru") {
        if (state.LocalizationsRu[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word];
          }
          return word;
        }

        return state.LocalizationsRu[word];
      } else if (state.langCodeX == "pt") {
        if (state.LocalizationsPt[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word];
          }
          return word;
        }

        return state.LocalizationsPt[word];
      } else if (state.langCodeX == "es") {
        if (state.LocalizationsEs[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word];
          }
          return word;
        }

        return state.LocalizationsEs[word];
      } else {
        if (state.LocalizationsEn[word] == undefined) {
          return word;
        }

        return state.LocalizationsEn[word];
      }

    },
  },
  mutations: {},
  actions: {},
  modules: {}
})