<template>
  <div class="home">
    <HomePage />
  </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery'
import HomePage from '@/components/HomePage.vue'
export default {
  name: 'HomeView',
  components: {
    HomePage
  },
  mounted() {
    $(`.nav-item`).removeClass(`active`);
    $(`.navbar-nav li`).eq(0).addClass(`active`);

    $(`#header-logo`).addClass(`hidden`);
  }
}
</script>
